import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <img src='https://firebasestorage.googleapis.com/v0/b/bautory-app.appspot.com/o/assets%2Fbranding%2FbautoryLogoIcon.png?alt=media&token=7072ae22-81ad-4e40-8962-eacc3ccd3aa7' alt='bautoryLogoIcon' />
      </header>
      <div>
        <img id='logoText' src='https://firebasestorage.googleapis.com/v0/b/bautory-app.appspot.com/o/assets%2Fbranding%2FbautoryLogoText.png?alt=media&token=f0af1430-5304-4570-8066-3e74caf03fc6' alt='bautoryLogoText' />
        <p id='motto'>
          Build better.
        </p>
      </div>
    </div>
  );
}

export default App;
